import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { Input } from '../../../../components/Input'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

interface Props {
  showFilter: boolean
  label: string
  keyFrom: string
  keyTo: string
  defaultSearch: Record<string, any>
  setValue: (key: any, val: any) => void
}

export const DashboardDateFilter = ({ showFilter, keyFrom, keyTo, setValue, defaultSearch, label }: Props) => {
  const [from, setFrom] = useState<Date | null>(defaultSearch?.[keyFrom] ? new Date(defaultSearch?.[keyFrom]) : null)
  const [to, setTo] = useState<Date | null>(defaultSearch?.[keyTo] ? new Date(defaultSearch?.[keyTo]) : null)

  const onChange = (key: string, date: Date | null): void => {
    if (!date) {
      setValue(key, '')
      return
    }

    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), 0, 0, 0))

    setValue(key, dayjs(utcDate).utc().format('YYYY-MM-DD'))
  }

  return (
    showFilter && (
      <>
        <div>
          <DatePicker
            isClearable={true}
            clearButtonClassName={
              'after:!text-black after:!bg-transparent after:!text-2xl !top-[12px] !after:font-thin'
            }
            shouldCloseOnSelect={true}
            showPopperArrow={false}
            customInput={<Input label={`${label} From`} />}
            selected={from}
            onChange={(date) => {
              setFrom(date)
              onChange(keyFrom, date)
            }}
            dayClassName={(date) => {
              if (date.toDateString() === from?.toDateString()) {
                return '!bg-primary !text-white'
              }
              return ''
            }}
            placeholderText={'mm/dd/yyyy'}
            popperClassName={'!shadow-2xl !rounded-xl'}
            calendarClassName={'!border-0 !rounded-xl'}
          />
        </div>

        <div>
          <DatePicker
            isClearable={true}
            clearButtonClassName={
              'after:!text-black after:!bg-transparent after:!text-2xl !top-[12px] !after:font-thin'
            }
            shouldCloseOnSelect={true}
            showPopperArrow={false}
            customInput={<Input label={`${label} To`} />}
            selected={to}
            onChange={(date) => {
              setTo(date)
              onChange(keyTo, date)
            }}
            dayClassName={(date) => {
              if (date.toDateString() === to?.toDateString()) {
                return '!bg-primary !text-white'
              }
              return ''
            }}
            placeholderText={'mm/dd/yyyy'}
            popperClassName={'!shadow-2xl !rounded-xl'}
            calendarClassName={'!border-0 !rounded-xl'}
          />
        </div>
      </>
    )
  )
}
