import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { AuthProvider } from 'components/AuthProvider'
import { Layout, AuthLayout, SessionLayout } from 'components/Layout'
import { ForgotPassword, SignIn, AuthCallback, Maintenance } from 'pages/public'
import {
  Dashboard,
  TodayAppointments,
  Appointments,
  Users,
  Flowsheet,
  Clinics,
  Pumps,
  Patients,
  SelectClinicScreen,
} from 'pages/private'
import { Toaster } from 'react-hot-toast'
import { SessionProvider } from 'components/SessionProvider'
import { Form } from './private/Forms'
import { QuestionnairesAdminDashboard } from './private/Forms/components/QuestionnairesAdminDashboard'
import { PatientFormScreen } from './private/Patients/PatientForm'
import { AppointmentFormScreen } from './private/Appointments/AppointmentForm'
import { LocalEMR } from 'components/LocalEMR'
import { FieldsManagement } from './private/Fields'
import { useAppVersion } from 'hooks/useAppVersion'
import { NoActiveUserScreen } from './public/NoActiveUser'
import { ClinicProtectedRoute } from 'components/ClinicProtectedRoute'
import { PatientAssignmentsScreen } from './private/Questionnaires/PatientAssignments'
import { PatientAssignmentsHistoryScreen } from './private/Questionnaires/PatientAssignmentHistory'
import { USER_ROLE } from 'types'
import { useIsRole } from 'hooks/useRole'

export const Pages = () => {
  useAppVersion()
  const isPatient = useIsRole(USER_ROLE.PATIENT)

  return (
    <>
      <Toaster />
      <Routes>
        <Route element={<Layout />}>
          <Route path='/login' element={<SignIn />} />
          <Route path='/auth-callback' element={<AuthCallback />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/select-clinic' element={<SelectClinicScreen />} />
          <Route path='/user-no-active' element={<NoActiveUserScreen />} />
          <Route path='/maintenance' element={<Maintenance />} />
        </Route>

        {/* Protected stack NOT for patients */}
        {!isPatient && (
          <>
            <Route element={<AuthLayout />}>
              <Route path='/' element={<Dashboard />} />
              <Route path='/form/:formName' element={<Form />} />

              {/* Admin routes */}
              <Route path='/users' element={<Users />} />
              <Route path='/fields-management' element={<FieldsManagement />} />
              <Route path='/clinics' element={<Clinics />} />

              {/* App routes */}
              <Route path='/clinics/:clinicId' element={<ClinicProtectedRoute />}>
                <Route path='today-appointments' element={<TodayAppointments />} />
                <Route path='pumps' element={<Pumps />} />
                <Route path='appointments' element={<Appointments />} />
                <Route path='questionnaires' element={<QuestionnairesAdminDashboard />} />
                <Route path='patients' element={<Patients />} />
                <Route
                  path='patients/new'
                  element={
                    <LocalEMR>
                      <PatientFormScreen isNewPatient />
                    </LocalEMR>
                  }
                />
                <Route path='patients/:id' element={<PatientFormScreen />} />
                <Route path='appointments/:id' element={<AppointmentFormScreen />} />
                <Route
                  path='sessions/:id'
                  element={
                    <SessionProvider>
                      <Flowsheet />
                    </SessionProvider>
                  }
                />
              </Route>
            </Route>

            {/* PDF-render shortcut route */}
            <Route element={<SessionLayout />}>
              <Route
                path='/sessions/:id'
                element={
                  <SessionProvider>
                    <Flowsheet />
                  </SessionProvider>
                }
              />
            </Route>
          </>
        )}

        {/* Patient's route stack */}
        {isPatient && (
          <Route element={<AuthLayout />}>
            <Route path='/' element={<Navigate to='/questionnaires' replace />} />
            <Route path='/questionnaires' element={<PatientAssignmentsScreen />} />
            <Route path='/questionnaires-history' element={<PatientAssignmentsHistoryScreen />} />
            <Route path='/form/:formName' element={<Form />} />
          </Route>
        )}

        {/* Catch any not found route */}
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    </>
  )
}

export const Providers = () => {
  return (
    <AuthProvider>
      <Toaster />
      <Pages />
    </AuthProvider>
  )
}
