import { Table } from 'components/Table'
import { IColumn, IOrderBy, TableSettings } from 'components/Table/types'
import { useUserSettings } from 'hooks/useUserSettings'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  AssignmentRule,
  QUESTIONNAIRE_ASSIGNMENT_FREQUENCY,
  QUESTIONNAIRE_ASSIGNMENT_NEXT_BY,
  QUESTIONNAIRE_ASSIGNMENT_STATUS,
} from 'types/dist'
import { Button } from 'components/Button'
import { useAssignmentRules } from 'hooks/useAssignmentRules'
import { capitalizeFirstLetter } from 'lib/string'
import * as Toast from 'components/Toast'
import { useAuth } from '../../../hooks'
import { AssignmentActive } from './components/AssignmentActive'
import { AssignmentDueDate } from './components/AssignmentDueDate'
import { AssignmentFrequency } from './components/AssignmentFrequency'
import { AssignmentStatus } from './components/AssignmentStatus'
import { assignmentRules as AssignmentRulesActions } from 'data'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

interface QuestionnaireFormProps {
  patientId: string
}

interface QuestionnairesTable {
  id: string
  name: string
  dueDate: {
    value: string
    render: JSX.Element
  }
  frequency: {
    value: QUESTIONNAIRE_ASSIGNMENT_FREQUENCY
    render: JSX.Element
  }
  active: {
    value: string
    render: JSX.Element
  }
  status: {
    value: QUESTIONNAIRE_ASSIGNMENT_STATUS
    render: JSX.Element
  }
  nextBy: {
    value: QUESTIONNAIRE_ASSIGNMENT_NEXT_BY
    render: JSX.Element
  }
  action: {
    value: string
    render: JSX.Element
  }
}

const defaultColumns: IColumn<QuestionnairesTable>[] = [
  { label: 'Name', key: 'name' },
  { label: 'Due Date', key: 'dueDate' },
  { label: 'Frequency', key: 'frequency' },
  { label: 'Active?', key: 'active' },
  { label: 'Status', key: 'status' },
  // { label: 'Next by', key: 'nextBy' },
  { label: 'Action', key: 'action' },
]

// const forms = [
//   {
//     id: '1',
//     name: 'EQ_SD_5L_Quality of Life',
//     type: 'eq5d5',
//     frequency: QUESTIONNAIRE_ASSIGNMENT_FREQUENCY.QUARTERLY,
//     active: true,
//     status: QUESTIONNAIRE_ASSIGNMENT_STATUS.DORMANT,
//     nextBy: QUESTIONNAIRE_ASSIGNMENT_NEXT_BY.PATIENT,
//   },
//   {
//     id: '2',
//     name: 'PDQ_39_Parkinson’s',
//     type: 'pdq39',
//     frequency: QUESTIONNAIRE_ASSIGNMENT_FREQUENCY.SEMI_ANNUALLY,
//     active: true,
//     status: QUESTIONNAIRE_ASSIGNMENT_STATUS.OVERDUE,
//     nextBy: QUESTIONNAIRE_ASSIGNMENT_NEXT_BY.PATIENT,
//   },
//   {
//     id: '3',
//     name: 'Provider Questionnaire',
//     type: 'provider',
//     frequency: QUESTIONNAIRE_ASSIGNMENT_FREQUENCY.QUARTERLY,
//     active: true,
//     status: QUESTIONNAIRE_ASSIGNMENT_STATUS.STARTED,
//     nextBy: QUESTIONNAIRE_ASSIGNMENT_NEXT_BY.PROVIDER,
//   },
//   {
//     id: '4',
//     name: 'Mini Mental',
//     type: 'mmse',
//     frequency: QUESTIONNAIRE_ASSIGNMENT_FREQUENCY.QUARTERLY,
//     active: true,
//     status: QUESTIONNAIRE_ASSIGNMENT_STATUS.DORMANT,
//     nextBy: QUESTIONNAIRE_ASSIGNMENT_NEXT_BY.PROVIDER,
//   },
//   {
//     id: '5',
//     name: 'Goldberg Depression',
//     type: 'phq9',
//     frequency: QUESTIONNAIRE_ASSIGNMENT_FREQUENCY.QUARTERLY,
//     active: true,
//     status: QUESTIONNAIRE_ASSIGNMENT_STATUS.DORMANT,
//     nextBy: QUESTIONNAIRE_ASSIGNMENT_NEXT_BY.PATIENT,
//   },
//   {
//     id: '6',
//     name: 'VF_14_QOL_Vision',
//     type: 'vf14',
//     frequency: QUESTIONNAIRE_ASSIGNMENT_FREQUENCY.ANNUALLY,
//     active: true,
//     status: QUESTIONNAIRE_ASSIGNMENT_STATUS.DORMANT,
//     nextBy: QUESTIONNAIRE_ASSIGNMENT_NEXT_BY.PATIENT,
//   },
// ]

export const QuestionnaireConsole: React.FC<QuestionnaireFormProps> = ({ patientId }) => {
  const { getSortListingByScreen, getColumns, saveSortListing, saveColumns } = useUserSettings()
  const { clinicId } = useAuth()
  const [columns, setColumns] = useState(
    getColumns<IColumn<QuestionnairesTable>[]>('patient/questionnaire-console') || defaultColumns,
  )
  const sortListingDefaults = getSortListingByScreen('patient/questionnaire-console')
  const [query, setQuery] = useState({
    patientId,
    orderby_field: sortListingDefaults?.fieldName || 'name',
    orderby_order: sortListingDefaults?.order || 'DESC',
  })
  const navigate = useNavigate()

  const { data, isLoading, mutate } = useAssignmentRules(query, { ignore: !patientId })

  const [loading, setLoading] = useState<boolean>(false)

  const forms: QuestionnairesTable[] = data?.data.map((x: AssignmentRule) => ({
    name: x.form.name,
    dueDate: {
      value: x.nextAssignment.dueDate,
      render: (
        <div onMouseDown={(e) => e.stopPropagation()}>
          <AssignmentDueDate
            assignmentId={x.nextAssignment.id}
            patientId={patientId}
            currentDueDate={dayjs(x.nextAssignment.dueDate).utc().format('MMM DD, YYYY')}
            mutate={mutate}
            setLoading={setLoading}
          />
        </div>
      ),
    },
    frequency: {
      value: x?.frequency || QUESTIONNAIRE_ASSIGNMENT_FREQUENCY.QUARTERLY,
      render: (
        <AssignmentFrequency
          assignmentRuleId={x.id}
          patientId={patientId}
          currentFrequency={x?.frequency}
          isReadOnly={!x.active}
          mutate={mutate}
          setLoading={setLoading}
        />
      ),
    },
    active: {
      value: x.active ? 'true' : 'false',
      render: (
        <AssignmentActive
          assignmentRuleId={x.id}
          patientId={patientId}
          isActive={x.active}
          mutate={mutate}
          setLoading={setLoading}
        />
      ),
    },
    status: {
      value: x.nextAssignment.status,
      render: <AssignmentStatus status={x.nextAssignment.status} answers={x.nextAssignment.answers} />,
    },
    nextBy: {
      value: x.nextAssignment.nextBy,
      render: (
        <span className={'text-base'}>{capitalizeFirstLetter(x.nextAssignment?.nextBy?.toLowerCase() || '-')}</span>
      ),
    },
    action: {
      value: x.form.type,
      render: (
        <Button
          disabled={!x.active}
          onClick={() =>
            navigate(
              `/form/${x.form.type}?version=v${x.form.version}&assignmentId=${x.nextAssignment.id}`,
            )
          }
        >
          Start
        </Button>
      ),
    },
  }))

  const onSaveTableSettings = (tableSettings: TableSettings) => {
    const columns = tableSettings.columns as unknown as IColumn<QuestionnairesTable>[]
    const columnsToSave = columns.length === 0 ? defaultColumns : columns
    setColumns(columnsToSave)
    saveColumns({
      screenName: 'patient/questionnaire-console',
      columns: tableSettings.columns,
    })
  }

  const orderBy = useMemo(() => {
    return { fieldName: query.orderby_field, order: query.orderby_order } as IOrderBy<QuestionnairesTable>
  }, [query.orderby_field, query.orderby_order])

  const onOrderBy = (options: IOrderBy<QuestionnairesTable>) => {
    setQuery({
      ...query,
      orderby_field: options.fieldName,
      orderby_order: options.order,
    })

    saveSortListing({
      screenName: 'patient/questionnaire-console',
      fieldName: options.fieldName,
      order: options.order,
    })
  }

  if (isLoading || !data?.data) {
    return null
  }

  return (
    <>
      <div className='shadow-md rounded-lg'>
        <div>
          <Table
            className='w-full'
            title={'Questionnaire Console'}
            showSearch={false}
            columns={columns}
            rows={forms}
            loading={loading}
            orderBy={orderBy}
            onOrderBy={onOrderBy}
            onEmptyState={
              <div className='flex justify-center h-64 items-center flex-col'>
                <div className='text-center mb-4'>
                  <h3 className='text-xl font-bold mb-4'>No Questionnaires</h3>
                  <p className='text-gray-500'>There are no questionnaires available for this patient.</p>
                </div>
                <Button
                  onClick={async () => {
                    setLoading(true)

                    await AssignmentRulesActions.create({
                      patientId: patientId,
                      rules: [
                        // TODO: needs to get the forms from the backend instead of hard coding form IDs
                        {
                          formId: 'def1d3fb-8b8b-455d-9eaa-3ceef886a75d',
                          clinicId,
                          frequency: 'quarterly',
                        },
                        {
                          formId: '83269c94-13c3-475f-bdaf-686198b190db',
                          clinicId,
                          frequency: 'quarterly',
                        },
                        {
                          formId: '55fe85c2-dc8a-4e9d-a0aa-9f1dbf4134c5',
                          clinicId,
                          frequency: 'quarterly',
                        },
                        {
                          formId: 'aba72581-c9b3-4740-b6f2-9d049a69427a',
                          clinicId,
                          frequency: 'quarterly',
                        },
                        {
                          formId: 'affc841a-ca5c-4269-b32a-a1262915c789',
                          clinicId,
                          frequency: 'quarterly',
                        },
                        {
                          formId: '8d67ea90-d241-4c1c-8ecf-7d65e7136ab7',
                          clinicId,
                          frequency: 'quarterly',
                        },
                        {
                          formId: 'ef1cd1c2-ac58-4e05-975e-ceb12e55d4b3',
                          clinicId,
                          frequency: 'quarterly',
                        },
                      ],
                    })

                    await mutate()

                    Toast.success('Patient successfully enrolled in the questionnaires.')

                    setLoading(false)
                  }}
                >
                  Enroll Patient into the Questionnaires
                </Button>
              </div>
            }
            onSaveSettings={onSaveTableSettings}
          />
        </div>
      </div>
    </>
  )
}
