import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { InfusionSessionHour, PreFlightBsReading } from 'types'

dayjs.extend(utc)
dayjs.extend(timezone)

function timeToMinutes(time: string) {
  const [hours, minutes] = time.split(':').map(Number)
  return hours * 60 + minutes
}

interface params {
  preFlightBsReadings: PreFlightBsReading | undefined
  infusionSessionHours: InfusionSessionHour | undefined
}

export function findLatestEntry({ preFlightBsReadings, infusionSessionHours }: params) {
  if (!preFlightBsReadings?.time && !infusionSessionHours?.time) return 'preFlightBsReadings'
  if (!preFlightBsReadings?.time) return 'infusionSessionHours'
  if (!infusionSessionHours?.time) return 'preFlightBsReadings'
  const minutes1 = timeToMinutes(preFlightBsReadings.time)
  const minutes2 = timeToMinutes(infusionSessionHours.time)

  return minutes1 > minutes2 ? 'preFlightBsReadings' : 'infusionSessionHours'
}

export function currentTime24H() {
  const now = new Date()
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  return `${hours}:${minutes}`
}

export function formatTimeToHHmm(timeString: string, utc?: boolean): string {
  if (timeString && timeString.length < 7) return timeString
  const date = new Date(timeString)
  const hours = utc ? date.getUTCHours() : date.getHours()
  const minutes = utc ? date.getUTCMinutes() : date.getMinutes()

  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}`
}

export const toUtc = (date: Date | string) => {
  const { timeZone: localTZ } = Intl.DateTimeFormat().resolvedOptions()

  const newDate = new Date(date)

  const utcTime = Date.UTC(
    newDate.getUTCFullYear(),
    newDate.getUTCMonth(),
    newDate.getUTCDate(),
    newDate.getUTCHours(),
    newDate.getUTCMinutes(),
    0,
    0,
  )

  return new Date(dayjs(new Date(utcTime)).tz('utc').tz(localTZ, true).toDate())
}

export const formatTimeToDateTime = (time: string, date?: Date | null | string) => {
  const [hour, minutes] = time.split(':')

  return dayjs(date)
    .set('hours', Number(hour))
    .set('minutes', Number(minutes))
    .toISOString()
}

export const getTodayData = (start_date?: string, end_date?: string): { start_date: string; end_date: string } => {
  const currentDate = new Date()
  const startDateTime = start_date ? new Date(start_date) : new Date(currentDate)
  const endDateTime = end_date ? new Date(end_date) : new Date(currentDate)

  // Set the time to the start of the day (00:00:00) for start_date
  startDateTime.setHours(0, 0, 0, 0)

  // Set the time to the end of the day (23:59:59) for end_date
  endDateTime.setHours(23, 59, 59, 999)

  // Convert the dates to ISO8601 format
  const isoStartDate = startDateTime.toISOString()
  const isoEndDate = endDateTime.toISOString()

  return {
    start_date: isoStartDate,
    end_date: isoEndDate,
  }
}

export const resetShortStartDate = (date: string) => {
  if (!date) return date

  const startDate = new Date(`${date}T00:00:00`)
  startDate.setHours(0, 0, 0, 0)

  const utcDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0))

  return dayjs(utcDate).utc().toISOString()
}

export const resetShortEndDate = (date: string) => {
  if (!date) return date

  const endDate = new Date(`${date}T00:00:00`)
  endDate.setHours(23, 59, 59, 999)

  const utcDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999))

  return dayjs(utcDate).utc().toISOString()
}

export const validateDOB = (value?: string | number | Date | dayjs.Dayjs | null | undefined) => {
  const dateValue = dayjs(value)
  const today = dayjs()

  if (!dateValue.isValid()) return 'Invalid date'
  if (dateValue.isAfter(today)) return 'DOB should not be a date in the future'
  if (today.get('year') - dateValue.get('year') >= 150) return 'Age cannot exceed 150 years. Please enter a valid date.'

  return true
}

export const isBeforeTodayEod = (date: string | Date) => {
  const { end_date: todayEod } = getTodayData()
  const target = dayjs(date)

  return dayjs(target).isBefore(todayEod)
}

export const displayDate = (date: string) => {
  return dayjs(date).format('MM/DD/YYYY')
}

export const formatDate = (date: string) => {
  return dayjs(date).utc().format('MMM DD, YYYY')
}
