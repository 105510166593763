import { useEffect } from 'react'

const version = process.env.REACT_APP_VERSION || ''

export const useAppVersion = () => {
  useEffect(() => {
    const appVersionEl = document.getElementById('app-version')

    if (appVersionEl) {
      appVersionEl.innerHTML = version
    }

    console.log(`APP VERSION: ${version}`)
    localStorage.setItem('appVersion', version)
  }, [])

  useEffect(() => {
    const currentVersion = localStorage.getItem('appVersion') || ''

    const fetchVersion = async () => {
      const isFormPage = window.location.pathname.startsWith('/form')

      try {
        const response = await fetch('/manifest.json')
        const data = await response.json()
        const newVersion = data?.version

        if (newVersion && !isFormPage && newVersion !== currentVersion) {
          localStorage.setItem('appVersion', newVersion)
          window.location.reload()
        }
      } catch (error) {
        console.error('Failed to fetch manifest version:', error)
      }
    }

    const interval = setInterval(fetchVersion, 30000) // every 30 seconds

    return () => clearInterval(interval)
  }, [])

  return {
    version,
  }
}
