import React, { ReactNode, useEffect, useState } from 'react'

export interface PopoverProps {
  children: ReactNode
  trigger: ReactNode
  position?: 'top' | 'bottom'
  isDisplayed: boolean
  setIsDisplayed: (val: boolean) => void
}

const Popover = ({ children, trigger, isDisplayed, setIsDisplayed, position = 'top' }: PopoverProps) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  useEffect(() => {
    const onClickOutside = () => {
      if (!isHovered && isDisplayed) {
        setIsDisplayed(false)
      }
    }

    document.addEventListener('click', onClickOutside)

    return () => {
      document.removeEventListener('click', onClickOutside)
    }
  }, [setIsDisplayed, isDisplayed, isHovered])

  return (
    <div className='relative inline-block' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className='inline-block cursor-pointer' onClick={() => setIsDisplayed(!isDisplayed)}>
        {trigger}
      </div>
      {isDisplayed && (
        <>
          <div
            className={`absolute z-10 bg-white text-black rounded-lg whitespace-nowrap left-1/2 -translate-x-1/2 ${position}-full `}
          >
            <div className='inline-flex p-4 flex-col gap-2 rounded-md shadow-md'>{children}</div>
          </div>
        </>
      )}
    </div>
  )
}

export default Popover
