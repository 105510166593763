import React, { useState, useMemo } from 'react'
import { Dropdown } from 'components/Dropdown'
import { Input } from 'components/Input'
import { Button } from 'components/Button'
import { medicines } from 'constants/FlowSheet'
import { FormControl } from './FormControl'
import { InfusionSession, SESSION_STATUS_TYPE } from 'types'
import { formatTimeToDateTime, formatTimeToHHmm } from 'lib/day'
import { useFieldDictionary } from 'hooks/useFields'

interface MedSectionProps {
  onSubmit: (newMedicine: string, time: string, comments: string) => Promise<void>
  data: InfusionSession
}
export const MedSection = ({ onSubmit, data }: MedSectionProps) => {
  const { fieldLabel, fieldValues } = useFieldDictionary()
  const { medicineAdministered } = data
  const [{ med, time, comments }, setValues] = useState({ med: '', time: '', comments: '' })
  const [isLoading, setIsLoading] = useState(false)

  const reversedMedicineAdministered = useMemo(() => {
    if (!medicineAdministered) return []

    return [...medicineAdministered].reverse()
  }, [medicineAdministered])

  const buttonIsDisabled = med?.length * comments.length * time?.length === 0
  const cleanValues = () => {
    setValues({ med: '', time: '', comments: '' })
  }

  const handleButtonClick = async () => {
    setIsLoading(true)
    await onSubmit(med, formatTimeToDateTime(time, data?.sessionStartDate), comments)
    setIsLoading(false)
    cleanValues()
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({ ...prev, [event.target.name]: event.target.value }))
  }

  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event?.target?.value) {
      const newDate = new Date()
      const newTime = `${newDate.getHours().toString().padStart(2, '0')}:${newDate
        .getMinutes()
        .toString()
        .padStart(2, '0')}`
      setValues((prev) => ({ med: event.target.value, time: prev.time || newTime, comments: prev.comments }))
    } else {
      setValues(prev => ({...prev, med: event.target.value}))
    }
  }

  const isSessionSigned = data.status === SESSION_STATUS_TYPE.SIGNED

  return (
    <div className='my-3'>
      {!isSessionSigned && (
        <div className='flex flex-row items-center gap-1'>
          <div className='p-1 rounded-md shadow-md flex flex-row  w-max'>
            <div className='pr-2 border-r'>
              <Input
                name="time"
                value={formatTimeToHHmm(time || '')}
                onChange={handleInputChange}
                label='Time'
                className='h-8'
                background='white'
                placeholder='7'
                inline
                type='time'
              />
            </div>
            <div className='pr-2 border-r'>
              <Dropdown
                withAutocomplete
                label={fieldLabel('med', 'Med')}
                options={fieldValues('med', medicines.map((med) => ({ label: med, value: med })))}
                className='h-8 w-44'
                background='white'
                placeholder='None'
                inline
                value={med}
                onChange={handleDropdownChange}
              />
            </div>
            <div className='pr-2 border-r min-w-[500px]'>
              <Input
                value={comments}
                onChange={handleInputChange}
                name='comments'
                label='Comments'
                className='h-8'
                background='white'
                placeholder='Add comments'
                inline
              />
            </div>
          </div>
          <Button disabled={buttonIsDisabled} onClick={handleButtonClick} className='min-h-8 h-8 bg-secondary px-8'>
            Add new med +{isLoading ? <span className='loading loading-spinner loading-sm' /> : <></>}
          </Button>
        </div>
      )}

      <div className='flex flex-wrap gap-2'>
        {reversedMedicineAdministered.map(({ name, time, comments }) => (
          <div key={`${name}${time}`}>
            <div className='p-1 rounded-md shadow-md flex flex-row  w-max'>
              <div className='pr-2 border-r'>
                <FormControl inline label='Time'>
                  {formatTimeToHHmm(time)}
                </FormControl>
              </div>
              <div className='pr-2 border-r'>
                <FormControl inline label='Med'>
                  {name}
                </FormControl>
              </div>
              {comments?.length && (
                <FormControl inline label='Comments'>
                  {comments}
                </FormControl>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
