import utc from 'dayjs/plugin/utc'
import React, { useState } from 'react'
import { ConfirmationModal } from './Modals/ConfirmationModal'
import SessionTimeInput from './SessionTimeInput'
import dayjs from 'dayjs'

dayjs.extend(utc)

interface SessionFinishTimeConfirmationProps {
  onConfirm: (finishDate: Date) => void
}

export const SessionFinishTimeConfirmation = ({ onConfirm }: SessionFinishTimeConfirmationProps) => {
  const currentDate = new Date()

  const [finishDate, setFinishDate] = useState<Date>(currentDate)

  return (
    <ConfirmationModal
      id='sessionFinishDateConfirmationModal'
      title={'Confirm session finish time'}
      description={
        <div className='mt-4'>
          <div className='flex items-center mt-2'>
            <span>Finish time:</span>
            <SessionTimeInput
              date={finishDate}
              className='ml-4 h-8'
              showButton={false}
              onSubmit={(time) => {
                if (!time) {
                  return
                }

                setFinishDate(new Date(time))
              }}
            />
          </div>
        </div>
      }
      disabled={!finishDate}
      onConfirm={() => onConfirm(finishDate)}
    />
  )
}
