import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormControl } from 'components/FormControl'
import { Input, InputProps } from 'components/Input'
import { InfusionSession, SESSION_STATUS_TYPE } from 'types'
import { getSectionBackground } from 'pages/private'
import { formatTimeToHHmm } from 'lib/day'
import clsx from 'clsx'

const fieldList = [
  { value: 'initials', label: 'Initials' },
  { value: 'time', label: 'Time', type: 'time' },
  { value: 'temperature', label: 'Temp', type: 'number' },
  { value: 'bloodPressure', label: 'B/P', type: 'bp' },
  { value: 'pulse', label: 'Pulse', type: 'number' },
  { value: 'respiratoryRate', label: 'RR', type: 'number' },
]

interface InfusionSessionHourReadingProps {
  data: InfusionSession
  onChange: (data: Partial<InfusionSession>) => void
  hour: number
}

export const InfusionSessionHourReading = ({ data, onChange, hour }: InfusionSessionHourReadingProps) => {
  const { control, watch } = useFormContext()

  const appointmentType = watch('type')
  const bgColor = getSectionBackground(appointmentType)

  const updateContext = (key: string, value?: any) => {
    const dataToUpdate: any = {}
    if (value === '' || !value) return
    if (key.split('.').pop() === 'time') {
      const sessionDate = data.sessionStartDate ? new Date(data.sessionStartDate) : new Date()
      const [hours, minutes] = value.split(':')
      sessionDate.setHours(Number(hours))
      sessionDate.setMinutes(Number(minutes))
      value = sessionDate.toISOString()
    }
    dataToUpdate[key] = value
    onChange(dataToUpdate)
  }

  const isSessionSigned = data?.status === SESSION_STATUS_TYPE.SIGNED
  const isSessionCanceled = data?.status === SESSION_STATUS_TYPE.SESSION_CANCELED
  const isReadOnly = isSessionSigned || isSessionCanceled

  return (
    <div>
      <div className={`flex flex-row ${bgColor} p-2`}>
        <span className='text-dark-gray font-bold'>{`HR${hour + 1}`}</span>
      </div>
      <div className='shadow-md grid-cols-8'>
        <div className='grid grid-flow-col grid-cols-6 justify-between space-x-0'>
          {fieldList.map((item) => {
            return (
              <div key={item.label} className={`col-span-1 flex-grow`}>
                <FormControl
                  label={item.label}
                  labelClassName='bg-gray-50 font-bold text-gray-500'
                  containerClassName='border-r'
                >
                  <div className='p-1 border-b border-gray-200 '>
                    <Controller
                      name={`infusionSessionHours.${hour}.${item.value}`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          disabled={item.value === 'initials'}
                          value={item.value === 'time' ? formatTimeToHHmm(field.value) : field.value}
                          className={clsx('h-8', {
                            'min-w-8': item.value === 'time',
                            'disabled:bg-white disabled:border-none disabled:text-primary': item.value === 'initials',
                          })}
                          type={item.type as InputProps['type']}
                          placeholder=''
                          onBlur={(e) => updateContext(field.name, e.target.value)}
                          readOnly={isReadOnly}
                          transparent={isReadOnly}
                        />
                      )}
                    />
                  </div>
                </FormControl>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
