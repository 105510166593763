import React, { useEffect, useRef } from 'react'
import { ConfirmationModalProps } from './types'
import { Button } from 'components/Button'
import { noop } from 'lib/utils'

export const openConfirmationModal = (id: string) => (document.getElementById(id) as HTMLDialogElement)?.showModal()
export const hideConfirmationModal = (id: string) => (document.getElementById(id) as HTMLDialogElement)?.close()

export const ConfirmationModal = ({
  id,
  title,
  description,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  onConfirm,
  disabled,
  onCancel = noop,
}: ConfirmationModalProps) => {
  const dialogRef = useRef<HTMLDialogElement>(null)
  const onConfirmClick = () => {
    onConfirm()
    hideConfirmationModal(id)
  }

  const onCancelClick = () => {
    onCancel?.()
    hideConfirmationModal(id)
  }

  useEffect(() => {
    if (!dialogRef.current) return
    const dialogElement = dialogRef.current

    const handleKeyDown = () => {
      onCancel()
    }
    dialogElement.addEventListener('cancel', handleKeyDown)

    return () => {
      dialogElement.removeEventListener('cancel', handleKeyDown)
    }
  }, [onCancel])

  return (
    <dialog ref={dialogRef} id={id} className='modal'>
      <form method='dialog' className='modal-box overflow-visible flex flex-col gap-6'>
        <div>
          {title ? <h2 className='text-2xl mb-2'>{title}</h2> : <></>}
          {description ? <p>{description}</p> : <></>}
        </div>
        <div className='flex gap-3 justify-end'>
          <Button background='red' onClick={onCancelClick}>
            {cancelText}
          </Button>
          <Button onClick={onConfirmClick} disabled={disabled}>
            {confirmText}
          </Button>
        </div>
      </form>
      <form method='dialog' className='modal-backdrop' onSubmit={onCancel}>
        <button>close</button>
      </form>
    </dialog>
  )
}
