import utc from 'dayjs/plugin/utc'
import React from 'react'
import { Input } from 'components/Input'
import { Controller } from 'react-hook-form'
import { AllergyInfo, DiabetesData, IcdSystem, InfusionSession, Patient, SESSION_STATUS_TYPE } from 'types'
import dayjs from 'dayjs'
import { OpenInNewTabIcon } from 'components/icons/OpenInNewTab.icon'
import { Link } from 'react-router-dom'
import { useAuth } from 'hooks'

dayjs.extend(utc)

interface PatientHeaderSectionProps {
  patient: Patient
  created: string
  control: (event: 'onBlur' | 'onChange') => any
  data?: InfusionSession
}

export const PatientHeaderSection = ({ patient, created, control, data }: PatientHeaderSectionProps) => {
  const { clinicId } = useAuth()
  const patientName = `${patient.firstName || ''} ${patient.lastName || ''}`.trim()
  const dob = dayjs(patient.dob).format('MMM DD, YYYY')
  const createdFormatted = dayjs(created).format('MMM DD, YYYY')
  const isSessionSigned = data?.status === SESSION_STATUS_TYPE.SIGNED
  const isSessionCanceled = data?.status === SESSION_STATUS_TYPE.SESSION_CANCELED
  const isReadOnly = isSessionSigned || isSessionCanceled

  const patientHeight = () => {
    const height = data?.appointment.height ?? 0
    const feet = Math.floor(height / 12)
    const inches = height - feet * 12
    return `${feet}'${inches}''`
  }

  const getAllergies = (allergies: AllergyInfo[]): string => {
    const result: string[] = []

    if (!allergies.length) {
      return 'none'
    }

    for (const allergy of allergies) {
      result.push(allergy.display)
    }
    result.sort((a, b) => (a > b ? 1 : -1))

    return result.join(', ')
  }

  const getDiabetesType = (diabetes: DiabetesData): string => {
    let result = 'none'
    if (diabetes[IcdSystem.ICD10]) {
      const keys = diabetes[IcdSystem.ICD10]?.map((value: string) => value.split('.')[0])
      const set = new Set(keys) // unique

      if (set.has('E10')) {
        result = 'Type 1'
      } else if (set.has('E11')) {
        result = 'Type 2'
      } else {
        result = 'Unknown'
      }
    }

    return result
  }

  return (
    <div className='pt-4 z-50 sticky top-12 shadow-md print:static'>
      <div className='w-full grid grid-cols-12'>
        <div className='bg-dark-gray col-span-4 border border-top-0 px-4 py-2 flex gap-2'>
          <Link to={`/clinics/${clinicId}/patients/${patient.id}`} target='_blank' className='flex gap-2 items-center'>
            <div className='text-white'>
              <span className='print:hidden'>Patient Name: </span>{' '}
              <span className='font-bold hover:underline text-nowrap'>{patientName}</span>
            </div>
            <span className='print:hidden'>
              <OpenInNewTabIcon color='white' width={16} height={16} />
            </span>
          </Link>
        </div>
        <div className='bg-athens-gray col-span-4 px-4  border-gray-200 border-r border-b  py-2'>
          <span className='text-dark-gray'>
            Date: <span className='font-bold'>{createdFormatted}</span>
          </span>
        </div>
        <div className='bg-athens-gray col-span-4  px-4 border-gray-200 border-r border-b  py-2'>
          <span className='text-dark-gray'>
            Height: <span className='font-bold'>{patientHeight()}</span>
          </span>
        </div>
        <div className='bg-dark-gray col-span-4 border border-top-0 px-4  py-2'>
          <span className='text-white'>
            DOB: <span className='font-bold'>{dob}</span>
          </span>
        </div>
        <div className='bg-athens-gray md:col-span-2 lg:col-span-3 px-4  border-gray-200 border-r border-b  py-2'>
          <span className='text-dark-gray'>
            Type: <span className='font-bold'>{getDiabetesType(data?.appointment?.diabetes || {})}</span>
          </span>
        </div>
        <div className='bg-athens-gray md:col-span-3 lg:col-span-3  px-4 border-gray-200 border-r border-b  py-2'>
          <span className='text-dark-gray'>
            Allergies: <span className='font-bold'>{getAllergies(data?.appointment?.allergies || [])}</span>
          </span>
        </div>
        <div className='bg-athens-gray md:col-span-3 lg:col-span-2 px-4 border-gray-200 border-r border-b  py-1'>
          <Controller
            name='patientWeight'
            control={control('onBlur')}
            render={({ field }) => (
              <Input
                readOnly={isReadOnly}
                label='Weight'
                className='h-6 rounded-none font-bold text-dark-gray'
                inline={true}
                {...field}
              />
            )}
          />
        </div>
      </div>
    </div>
  )
}
