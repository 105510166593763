import React, { useEffect, useMemo, useState, useRef } from 'react'
import { get } from 'lodash'
import { FormControl } from 'components/FormControl'
import { Input } from 'components/Input'
import { Dropdown } from 'components/Dropdown'
import { Button } from 'components/Button'
import { InfusionSession, InfusionSessionInterval, SESSION_STATUS_TYPE, TARGET_TYPE, USER_ROLE } from 'types'
import { Controller, useFormContext } from 'react-hook-form'
import { pumpingSessionOptions } from 'pages/private/Flowsheet/constants'
import { MINUTES_IN_HOUR } from 'constants/FlowSheet'
import PumpDropdown from 'components/PumpDropdown'
import useRolePrivilege from 'hooks/useRolePrivilege'
import * as Toast from 'components/Toast'
import { useSessionContext } from 'components/SessionProvider'
import { BsInput } from './BsInput/BsInput'
import { formatTimeToHHmm } from 'lib/day'
import useDisableKeyboardNavigation from 'hooks/useDisableKeyboardNavigation'
import { DeleteRowModal } from 'components/Modals/DeleteRowModel'
import { useFieldDictionary } from 'hooks/useFields'
import { noop } from 'lib/utils'
import { cn } from 'lib/cn'
import { LongPressContainer } from './LongPressContainer'
import { useAuth } from 'hooks'

interface PumpingSessionProps {
  data: InfusionSession
  onChange: (data: Partial<InfusionSession>) => void
  deleteInfusionInterval: (hour: number, interval: number) => Promise<boolean>
  canPump: boolean
  hour: number
}

export const PumpingSession = ({ data, onChange, canPump, hour, deleteInfusionInterval }: PumpingSessionProps) => {
  const { userDetail } = useAuth()
  const [intervalToDelete, setIntervalToDelete] = useState<{hourIndex: number; intervalIndex: number} | null>(null)
  const { fieldLabel, fieldValues } = useFieldDictionary()
  const { control, watch, setValue } = useFormContext()
  const { updateAddHour, numHours } = useSessionContext()
  const { pumpingSectionEditClass } = useRolePrivilege()
  const [activeRow, setActiveRow] = useState<number>(0)
  const defaultRow = data.type === 'UR1' ? 4 : 2
  const [row, setRow] = useState<number>(
    data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.length ?? defaultRow
  )
  const [openConfirmDeleteRowModal, setOpenConfirmDeleteRowModal] = useState(false)
  const [openWarnPermanentRowDeleteModal, setOpenWarnPermanentRowDeleteModal] = useState(false)
  const updates = useRef<any>({})
  const pumpingSessionRef = useRef<HTMLDivElement>(null)
  useDisableKeyboardNavigation(pumpingSessionRef)

  const pumpRow = useMemo(() => {
    const intervals = data?.infusionSessionHours?.[hour]?.infusionSessionIntervals || []
    // const nonEmptyIntervals = intervals.filter(interval => !interval.isDeleted).filter((interval) => Object.keys(interval).length > 0)
    const nonEmptyIntervals = intervals.filter((interval) => Object.keys(interval).length > 0)
    return nonEmptyIntervals.length > 0 ? nonEmptyIntervals.length - 1 : 0
  }, [data, hour])

  const type = watch('type')

  const recordMutation = (key: string, value: any) => {
    setValue(key, value)
    updates.current = { ...updates.current, [key]: value }
  }

  const calculateIntervalDuration = useMemo(
    () => (key: number) => {
      const currentIntervalDuration =
        data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key]?.intervalDuration
      if (currentIntervalDuration) {
        return currentIntervalDuration
      }

      let totalTime = 0
      if (key > 0) {
        for (let i = 0; i < key; i++) {
          if (!data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[i]?.isDeleted) {
            const value =
              data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[i]?.intervalDuration?.toString() ?? '0'
            totalTime += parseInt(value, 10)
          }
        }
      }

      const calculateDurationForRow = (duration: number) => {
        return totalTime + duration > MINUTES_IN_HOUR ? MINUTES_IN_HOUR - totalTime : duration
      }

      if (row > 2) {
        if (key === 0 || key === 1) {
          return calculateDurationForRow(14)
        } else if (key === 2 || key === 3) {
          return calculateDurationForRow(16)
        }
      } else if (row <= 2) {
        if (key === 0) {
          return calculateDurationForRow(25)
        } else if (key === 1) {
          return calculateDurationForRow(35)
        }
      }

      return calculateDurationForRow(MINUTES_IN_HOUR)
    },
    [data, hour, row]
  )

  const updateContext = (row: number, key?: string, value?: any) => {
    if (!canPump || !key || value === '') return

    if (key === 'pumpPaused') {
      if (!value && row !== pumpRow) return

      key = `infusionSessionHours.${hour}.infusionSessionIntervals.${row}.pumpPaused`

      if (value) {
        recordMutation(`infusionSessionHours.${hour}.infusionSessionIntervals.${row}.qValue`, '0')
        recordMutation(`infusionSessionHours.${hour}.infusionSessionIntervals.${row}.drValue`, '0')
      }
    } else if (key.endsWith('.time')) {
      const sessionDate = new Date(data.sessionStartDate || Date.now())
      const [hours, minutes] = value.split(':')
      sessionDate.setHours(Number(hours))
      sessionDate.setMinutes(Number(minutes))
      value = sessionDate.toISOString()
    }

    recordMutation(key, value)

    const currentInterval = data.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[row]

    const intervalDurationKey = `infusionSessionHours.${hour}.infusionSessionIntervals.${row}.intervalDuration`
    const pumpIdKey = `infusionSessionHours.${hour}.infusionSessionIntervals.${row}.pumpId`

    if (!currentInterval?.intervalDuration && !key.endsWith('.intervalDuration')) {
      recordMutation(intervalDurationKey, calculateIntervalDuration(row))
    }

    if (!currentInterval?.pumpId) {
      const previousRowPumpId =
        row > 0
          ? data.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[row - 1]?.pumpId
          : data.infusionSessionHours?.[hour - 1]?.infusionSessionIntervals?.[
              data.infusionSessionHours?.[hour - 1]?.infusionSessionIntervals?.length - 1
            ]?.pumpId

      if (previousRowPumpId) {
        recordMutation(pumpIdKey, previousRowPumpId)
      }
    }
    onChange({ ...updates.current })
    updates.current = {}
  }

  const incrementRow = () => {
    setRow(row + 1)
  }

  const deleteCurrentRow = () => {
    setOpenWarnPermanentRowDeleteModal(false)
    setIntervalToDelete(null)

    if (!intervalToDelete) return

    deleteInfusionInterval(intervalToDelete.hourIndex, intervalToDelete.intervalIndex)
  }

  useEffect(() => {
    if (hour < (data?.infusionSessionHours?.length ?? 0) - 1)
      setRow(data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.length ?? 0)
    else {
      if (type === 'UR1') {
        if ((data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.length ?? 0) < 4) setRow(4)
      } else {
        if ((data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.length ?? 0) <= 2) setRow(2)
      }
    }
  }, [type, data, hour])

  useEffect(() => {
    const totalInfusionHours = data?.infusionSessionHours?.length ?? 0
    const currentInfusionIntervals = Array.isArray(data?.infusionSessionHours)
      ? data?.infusionSessionHours[hour]?.infusionSessionIntervals ?? []
      : []

    const canAddHour =
      (currentInfusionIntervals.length >= 1 &&
        currentInfusionIntervals[currentInfusionIntervals.length - 1].completed) ||
      activeRow === row

    if ((hour === totalInfusionHours || hour === totalInfusionHours - 1) && numHours === totalInfusionHours) {
      updateAddHour(canAddHour)
    }
  }, [activeRow, row, data, hour, numHours, updateAddHour])

  useEffect(() => {
    if (canPump) {
      let row = 0
      const infusionSessionIntervals = Array.isArray(data?.infusionSessionHours)
        ? data?.infusionSessionHours[hour]?.infusionSessionIntervals ?? []
        : []
      for (let r = 0; r < infusionSessionIntervals.length; r++) {
        if (infusionSessionIntervals[r].completed) row += 1
      }
      setActiveRow(row)
    }
  }, [data, canPump, hour])

  const isSessionSigned = data?.status === SESSION_STATUS_TYPE.SIGNED
  const isSessionCanceled = data?.status === SESSION_STATUS_TYPE.SESSION_CANCELED
  const isReadOnly = isSessionSigned || isSessionCanceled

  const onDeleteInterval = (hourIndex: number, intervalIndex: number) => {
    const interval = get(data, `infusionSessionHours.${hourIndex}.infusionSessionIntervals.${intervalIndex}`) as unknown as InfusionSessionInterval

    if (!interval || isReadOnly) return
    if (interval.isDeleted) return

    setIntervalToDelete({ hourIndex, intervalIndex })
    setOpenConfirmDeleteRowModal(true)
  }

  const hasDeleteAccess = () => {
    return (userDetail?.role === USER_ROLE.ADMINISTRATOR || userDetail?.role === USER_ROLE.PROVIDER)
  }

  return (
    <div ref={pumpingSessionRef}>
      {openConfirmDeleteRowModal && (
        <DeleteRowModal
          id='rowConfirmationRowDeleteModal'
          open
          title={'Confirm'}
          description={'Are you intended to delete this row?'}
          onConfirm={() => {
            setOpenConfirmDeleteRowModal(false)
            setOpenWarnPermanentRowDeleteModal(true)
          }}
          onCancel={() => {
            setOpenConfirmDeleteRowModal(false)
            setIntervalToDelete(null)
          }}
        />
      )}

      {openWarnPermanentRowDeleteModal && (
        <DeleteRowModal
          id='warnPermanentRowDeleteModal'
          open
          title={'Confirm'}
          description={'This row will be permanently deleted, are you sure?'}
          onConfirm={deleteCurrentRow}
          onCancel={() => {
            setOpenWarnPermanentRowDeleteModal(false)
            setIntervalToDelete(null)
          }}
        />
      )}
      <div className='mb-4 rounded-md shadow-md'>
        <div className='grid grid-cols-11 justify-between space-x-0'>
          <div className=' flex-grow'>
            <FormControl
              label='Initials'
              labelClassName='bg-gray-50 font-bold text-gray-500'
              containerClassName='border-x'
            >
              {Array.from({ length: row }, (_, index) => index).map((key) => {
                const isDeleted = data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key]?.isDeleted

                return (
                  <LongPressContainer
                    key={key}
                    onLongPress={() => hasDeleteAccess() ? onDeleteInterval(hour, key) : noop}
                    className={cn(`relative p-1 first:border-t border-b border-gray-200 flex justify-between items-center ${pumpingSectionEditClass({
                      rowNumber: key,
                      activeRow: activeRow,
                      attribute: 'initials',
                      row: data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key],
                    })}`, {
                      'bg-red-100': intervalToDelete?.hourIndex === hour && intervalToDelete.intervalIndex === key,
                      'print:hidden': isDeleted
                    })}
                  >
                    <Controller
                      name={`infusionSessionHours.${hour}.infusionSessionIntervals.${key}.initials`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          value={field.value || ''}
                          disabled
                          className='h-8 border-yellow-200  disabled:bg-white disabled:border-none disabled:text-primary'
                          type={'text'}
                          placeholder=''
                          background={'default'}
                          onBlur={(e) => updateContext(key, field.name, e.target.value)}
                        />
                      )}
                    />

                    {isDeleted && (
                      <>
                        <div className='absolute h-[1px] bg-gray-400 w-full left-0' />
                        <div className='absolute inset-0 opacity-45 bg-white' />
                      </>
                    )}
                  </LongPressContainer>
                )
              })}
            </FormControl>
          </div>
          <div className=' flex-grow print:col-span-2 '>
            <FormControl
              label='Pump Start'
              labelClassName='font-bold text-gray-500 bg-gray-50 whitespace-nowrap overflow-hidden overflow-ellipsis'
              containerClassName='border-r'
            >
              {Array.from({ length: row }, (_, index) => index).map((key) => {
                const isDeleted = data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key]?.isDeleted

                return (
                  <div
                    key={key}
                    className={cn(`relative p-1 border-t border-gray-200 flex items-center justify-center ${pumpingSectionEditClass({
                      rowNumber: key,
                      activeRow: activeRow,
                      attribute: 'pumpPaused',
                      row: data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key],
                    })}`, {
                      'bg-red-100': intervalToDelete?.hourIndex === hour && intervalToDelete.intervalIndex === key,
                      'print:hidden': isDeleted
                    })}
                  >
                    {(data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key]?.pumpPaused === true) ? (
                      <span
                        onClick={() =>isDeleted ? noop : updateContext(key, 'pumpPaused', false)}
                        className='font-bold text-sm leading-8 mni-h-8 text-gray-400 whitespace-nowrap overflow-hidden overflow-ellipsis cursor-pointer print:hidden'
                      >
                        Pump paused
                      </span>
                    ) : key === pumpRow && hour === (data.infusionSessionHours?.length ?? 0) - 1 ? (
                      <Button
                        disabled={isReadOnly || isDeleted}
                        className='min-h-8 h-8'
                        onClick={() => updateContext(key, 'pumpPaused', true)}
                      >
                        Pump Pause
                      </Button>
                    ) : (
                      <span className='font-bold text-sm leading-8 mni-h-8 text-gray-400 whitespace-nowrap overflow-hidden overflow-ellipsis'>
                        --
                      </span>
                    )}

                    {isDeleted && (
                      <>
                        <div className='absolute h-[1px] bg-gray-400 w-full left-0' />
                        <div className='absolute inset-0 opacity-45 bg-white' />
                      </>
                    )}
                  </div>
                )
              })}
              <div className={`p-1 border-t border-gray-200 flex justify-center`}>
                <span className='font-bold text-sm leading-8 text-gray-400 whitespace-nowrap overflow-hidden overflow-ellipsis'>
                  {hour !== (data.infusionSessionHours?.length ?? 0) - 1 ? 'Pump Stop' : '--'}
                </span>
              </div>
            </FormControl>
          </div>
          <div className='flex-grow col-span-2 print:col-span-1'>
            <FormControl
              label={isSessionSigned ? 'Timers' : 'Sum of timers cannot exceed 60'}
              labelClassName='bg-gray-50 text-sm font-thin leading-6 text-gray-500 whitespace-nowrap overflow-hidden overflow-ellipsis justify-center'
              containerClassName='border-r'
            >
              {Array.from({ length: row }, (_, index) => index).map((key) => {
                const isDeleted = data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key]?.isDeleted

                return (
                  <div
                    key={key}
                    className={cn(`relative p-1 border-t border-gray-200 flex flex-row  justify-center align-middle items-center gap-x-2 ${pumpingSectionEditClass(
                      {
                        rowNumber: key,
                        activeRow: activeRow,
                        attribute: 'intervalDuration',
                        row: data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key],
                      }
                    )} print:gap-0`, {
                      'bg-red-100': intervalToDelete?.hourIndex === hour && intervalToDelete.intervalIndex === key,
                      'print:hidden': isDeleted
                    })}
                  >
                    <span className='font-thin text-sm print:hidden'>Set timer</span>
                    <Controller
                      name={`infusionSessionHours.${hour}.infusionSessionIntervals.${key}.intervalDuration`}
                      control={control}
                      render={({ field }) => {
                        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                          let totalTime = 0
                          if (key > 0) {
                            for (let i = 0; i < key; i++) {
                              const isDeleted = get(data, `infusionSessionHours.${hour}.infusionSessionIntervals.${i}.isDeleted`, false)
                              if (!isDeleted) {
                                const value =
                                  data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[
                                    i
                                  ]?.intervalDuration?.toString() ?? '0'
                                totalTime += parseInt(value)
                              }
                            }
                          }
                          console.log('totaltime: ', totalTime)
                          const inputValue = e.target.value
                          const newValue = inputValue && inputValue.length > 0 ? parseInt(inputValue) : 0
                          if (newValue + totalTime <= MINUTES_IN_HOUR) {
                            field.onChange(parseInt(inputValue))
                          } else {
                            Toast.error('Sum of timers cannot exceed 60!')
                          }
                        }
                        return (
                          <Input
                            {...field}
                            value={field.value ?? ''}
                            className='h-8 w-14 print:w-7 print:p-0'
                            type='number'
                            placeholder={calculateIntervalDuration(key).toString()}
                            onBlur={(e) => updateContext(key, field.name, e.target.value)}
                            onChange={handleInputChange}
                            readOnly={isReadOnly || isDeleted}
                            transparent={isReadOnly}
                          />
                        )
                      }}
                    />
                    <span className='font-thin text-sm'>min</span>

                    {isDeleted && (
                      <>
                        <div className='absolute h-[1px] bg-gray-400 w-full left-0' />
                        <div className='absolute inset-0 opacity-45 bg-white' />
                      </>
                    )}
                  </div>
                )
              })}
              <div className='p-1 border-t border-gray-200 flex flex-row  justify-center align-middle items-center gap-x-2 print:hidden'>
                <Button
                  className='min-h-8 h-8 text-white bg-secondary'
                  fullWidth
                  disabled={
                    (data.infusionSessionHours?.length ?? 0) - 1 !== hour ||
                    activeRow < row ||
                    calculateIntervalDuration(row + 1) <= 0
                  }
                  onClick={incrementRow}
                >
                  Add Interval
                </Button>
              </div>
            </FormControl>
          </div>
          <div className='flex-grow col-span-2'>
            <FormControl label='Time' labelClassName='bg-gray-50 font-bold text-gray-500' containerClassName='border-r'>
              {Array.from({ length: row }, (_, index) => index).map((key) => {
                const isDeleted = data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key]?.isDeleted

                return (
                  <div
                    key={key}
                    className={cn(`relative p-1 overflow-auto border-t ${pumpingSectionEditClass({
                      rowNumber: key,
                      activeRow: activeRow,
                      attribute: 'time',
                      row: data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key],
                    })} flex items-center`, {
                      'bg-red-100': intervalToDelete?.hourIndex === hour && intervalToDelete.intervalIndex === key,
                      'print:hidden': isDeleted
                    })}
                  >
                    <Controller
                      name={`infusionSessionHours.${hour}.infusionSessionIntervals.${key}.time`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          value={formatTimeToHHmm(field.value) || ''}
                          type='time'
                          className={`h-8 border rounded-md font-thin w-full flex-1`}
                          background={'white'}
                          onBlur={(e) => updateContext(key, field.name, e.target.value)}
                          readOnly={isReadOnly || isDeleted}
                          transparent={isReadOnly || isDeleted}
                          fullWidth
                        />
                      )}
                    />

                    {isDeleted && (
                      <>
                        <div className='absolute h-[1px] bg-gray-400 w-full left-0' />
                        <div className='absolute inset-0 opacity-45 bg-white' />
                      </>
                    )}
                  </div>
                )
              })}
            </FormControl>
          </div>
          <div className=' flex-grow '>
            <FormControl label='B/S' labelClassName='bg-gray-50 font-bold text-gray-500' containerClassName='border-r'>
              {Array.from({ length: row }, (_, index) => index).map((key) => {
                const isDeleted = data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key]?.isDeleted

                return (
                  <div
                    key={key}
                    className={cn(`relative p-1 border-t border-gray-200 ${pumpingSectionEditClass({
                      rowNumber: key,
                      activeRow: activeRow,
                      attribute: 'bloodSugar',
                      row: data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key],
                    })} flex items-center`, {
                      'bg-red-100': intervalToDelete?.hourIndex === hour && intervalToDelete.intervalIndex === key,
                      'print:hidden': isDeleted
                    })}
                  >
                    <Controller
                      name={`infusionSessionHours.${hour}.infusionSessionIntervals.${key}.bloodSugar`}
                      control={control}
                      render={({ field }) => (
                        <BsInput
                          {...field}
                          value={field.value || ''}
                          target={data.target as TARGET_TYPE}
                          className='h-8'
                          placeholder=''
                          type='number'
                          onBlur={(e) => updateContext(key, field.name, e.target.value)}
                          readOnly={isReadOnly || isDeleted}
                          showColorCode={false}
                        />
                      )}
                    />

                    {isDeleted && (
                      <>
                        <div className='absolute h-[1px] bg-gray-400 w-full left-0' />
                        <div className='absolute inset-0 opacity-45 bg-white' />
                      </>
                    )}
                  </div>
                )
              })}
            </FormControl>
          </div>
          <div className=' flex-grow '>
            <FormControl
              label={fieldLabel('qInterval', 'Q')}
              labelClassName='bg-gray-50 font-bold text-gray-500'
              containerClassName='border-r'
            >
              {Array.from({ length: row }, (_, index) => index).map((key) => {
                const isDeleted = data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key]?.isDeleted

                return (
                  <div
                    key={key}
                    className={cn(`relative p-1 border-t border-gray-200 ${pumpingSectionEditClass({
                      rowNumber: key,
                      activeRow: activeRow,
                      attribute: 'qValue',
                      row: data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key],
                    })} flex items-center`, {
                      'bg-red-100': intervalToDelete?.hourIndex === hour && intervalToDelete.intervalIndex === key,
                      'print:hidden': isDeleted
                    })}
                  >
                    <Controller
                      key={key}
                      name={`infusionSessionHours.${hour}.infusionSessionIntervals.${key}.qValue`}
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          {...field}
                          value={field.value || ''}
                          onChange={({ target: { value } }) => {
                            if (field.value !== value) {
                              field.onChange(value)
                              updateContext(key, field.name, value)
                            }
                          }}
                          required
                          options={fieldValues('qInterval', pumpingSessionOptions.qValue)}
                          className={'h-8'}
                          readOnly={isReadOnly || isDeleted}
                          transparent={isReadOnly || isDeleted}
                        />
                      )}
                    />

                    {isDeleted && (
                      <>
                        <div className='absolute h-[1px] bg-gray-400 w-full left-0' />
                        <div className='absolute inset-0 opacity-45 bg-white' />
                      </>
                    )}
                  </div>
                )
              })}
            </FormControl>
          </div>
          <div className=' flex-grow '>
            <FormControl
              label={fieldLabel('vInterval', 'V')}
              labelClassName='bg-gray-50 font-bold text-gray-500'
              containerClassName='border-r'
            >
              {Array.from({ length: row }, (_, index) => index).map((key) => {
                const isDeleted = data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key]?.isDeleted

                return (
                  <div
                    key={key}
                    className={cn(`relative p-1 border-t border-gray-200 ${pumpingSectionEditClass({
                      rowNumber: key,
                      activeRow: activeRow,
                      attribute: 'drValue',
                      row: data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key],
                    })} flex items-center`, {
                      'bg-red-100': intervalToDelete?.hourIndex === hour && intervalToDelete.intervalIndex === key,
                      'print:hidden': isDeleted
                    })}
                  >
                    <Controller
                      name={`infusionSessionHours.${hour}.infusionSessionIntervals.${key}.drValue`}
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          {...field}
                          value={field.value || ''}
                          onChange={({ target: { value } }) => {
                            if (field.value !== value) {
                              field.onChange(value)
                              updateContext(key, field.name, value)
                            }
                          }}
                          options={fieldValues('vInterval', pumpingSessionOptions.drValue)}
                          className={'h-8'}
                          required
                          readOnly={isReadOnly || isDeleted}
                          transparent={isReadOnly || isDeleted}
                        />
                      )}
                    />

                    {isDeleted && (
                      <>
                        <div className='absolute h-[1px] bg-gray-400 w-full left-0' />
                        <div className='absolute inset-0 opacity-45 bg-white' />
                      </>
                    )}
                  </div>
                )
              })}
            </FormControl>
          </div>
          <div className=' flex-grow '>
            <FormControl
              label='Glucose'
              labelClassName='bg-gray-50 font-bold text-gray-500'
              containerClassName='border-r'
            >
              {Array.from({ length: row }, (_, index) => index).map((key) => {
                const isDeleted = data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key]?.isDeleted

                return (
                  <div
                    key={key}
                    className={cn(`relative p-1 border-t border-gray-200 ${pumpingSectionEditClass({
                      rowNumber: key,
                      activeRow: activeRow,
                      attribute: 'glucose',
                      row: data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key],
                    })} flex items-center`, {
                      'bg-red-100': intervalToDelete?.hourIndex === hour && intervalToDelete.intervalIndex === key,
                      'print:hidden': isDeleted
                    })}
                  >
                    <Controller
                      name={`infusionSessionHours.${hour}.infusionSessionIntervals.${key}.glucose`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          value={field.value || ''}
                          type="number"
                          className='h-8'
                          placeholder=''
                          onBlur={(e) => updateContext(key, field.name, e.target.value)}
                          readOnly={isReadOnly || isDeleted}
                          transparent={isReadOnly || isDeleted}
                        />
                      )}
                    />

                    {isDeleted && (
                      <>
                        <div className='absolute h-[1px] bg-gray-400 w-full left-0' />
                        <div className='absolute inset-0 opacity-45 bg-white' />
                      </>
                    )}
                  </div>
                )
              })}
            </FormControl>
          </div>
          <div className=' flex-grow '>
            <FormControl label='Pump' labelClassName='bg-gray-50 font-bold text-gray-500' containerClassName='border-r'>
              {Array.from({ length: row }, (_, index) => index).map((key) => {
                const isDeleted = data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key]?.isDeleted

                return (
                  <div
                    key={key}
                    className={cn(`relative p-1 border-t border-gray-200 ${pumpingSectionEditClass({
                      rowNumber: key,
                      activeRow: activeRow,
                      attribute: 'pumpId',
                      row: data?.infusionSessionHours?.[hour]?.infusionSessionIntervals?.[key],
                    })} flex items-center`, {
                      'bg-red-100': intervalToDelete?.hourIndex === hour && intervalToDelete.intervalIndex === key,
                      'print:hidden': isDeleted
                    })}
                  >
                    <Controller
                      name={`infusionSessionHours.${hour}.infusionSessionIntervals.${key}.pumpId`}
                      control={control}
                      render={({ field }) => (
                        <PumpDropdown
                          {...field}
                          value={field.value || ''}
                          onChange={(value) => {
                            if (field.value !== value) {
                              field.onChange(value)
                              updateContext(key, field.name, value)
                            }
                          }}
                          className={isReadOnly || isDeleted ? '' : 'h-8'}
                          readOnly={isReadOnly || isDeleted}
                          transparent={isReadOnly || isDeleted}
                        />
                      )}
                    />

                    {isDeleted && (
                      <>
                        <div className='absolute h-[1px] bg-gray-400 w-full left-0' />
                        <div className='absolute inset-0 opacity-45 bg-white' />
                      </>
                    )}
                  </div>
                )
              })}
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  )
}
