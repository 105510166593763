import React, { useState } from 'react'
import { formatTimeToDateTime, formatTimeToHHmm } from '../lib/day'
import { Button } from './Button'
import { Input } from './Input'

interface SessionTimeInputProps {
  date?: Date
  label?: string
  onSubmit: (date: string) => void
  className?: string
  showButton?: boolean
  isReadOnly?: boolean
}

const SessionTimeInput = ({
  date,
  label,
  onSubmit,
  className,
  isReadOnly,
  showButton = true,
}: SessionTimeInputProps) => {
  const [value, setValue] = useState(date?.toISOString())

  return (
    <div className='flex items-end'>
      <Input
        label={label}
        className={className || 'h-8'}
        background='white'
        type='time'
        value={value ? formatTimeToHHmm(value) : undefined}
        onChange={(e) => {
          if (!e.target.value) {
            setValue(undefined)
            return
          }

          const newDate = new Date(formatTimeToDateTime(e.target.value, date?.toISOString())).toISOString()

          setValue(newDate)

          if (!showButton && newDate) {
            onSubmit(newDate)
          }
        }}
        readOnly={isReadOnly}
      />
      {showButton && (
        <Button
          className='flex-1 ml-2 min-h-0 h-8 font-normal'
          onClick={() => value && onSubmit(value)}
          disabled={!value}
        >
          OK
        </Button>
      )}
    </div>
  )
}

export default SessionTimeInput
