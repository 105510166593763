import { Button } from 'components/Button'
import * as Toast from 'components/Toast'
import { assignments as AssignmentActions } from 'data'
import React, { Dispatch, SetStateAction, Suspense, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  CalculateScoreFunction,
  FormResult,
  FormUserType,
  QUESTIONNAIRE_ASSIGNMENT_STATUS,
  Score,
  Section,
  SectionAnswers,
  USER_ROLE,
} from 'types/dist'
import { useAuth } from '../../../hooks'
import { useAssignment } from '../../../hooks/useAssignment'
import { FormFooter } from './components/FormFooter'
import { FormComponentRenderer } from './renderer'
import { getAnswersFromSections, getQuestions, getSectionAnswers } from './utils'

const loadFormComponent = (
  formName: string,
  version: string,
  setError: Dispatch<SetStateAction<boolean>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
) => {
  return React.lazy(async () => {
    setLoading(true)

    try {
      return await import(`./forms/${formName}/${version}`)
    } catch (e) {
      setError(true)
      return () => <></>
    } finally {
      setLoading(false)
    }
  })
}

export const Form = () => {
  const { formName } = useParams()
  const [searchParams] = useSearchParams()

  const [FormComponent, setFormComponent] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({
    started: false,
    submitted: false,
    readonly: false,
  })
  const [showSubmitScreen, setShowSubmitScreen] = useState(false)
  const [error, setError] = useState(false)

  const navigate = useNavigate()

  const [displayFormName, setDisplayFormName] = useState('')
  const [calculateScore, setCalculateScore] = useState<CalculateScoreFunction | null>(null)
  const [scores, setScores] = useState<Score[]>([])
  const [sections, setSections] = useState<Section[]>([])

  const [userType, setUserType] = useState<FormUserType>(FormUserType.PATIENT)
  const [sectionAnswers, setSectionAnswers] = useState<SectionAnswers[] | null>(null)

  const assignmentId = searchParams.get('assignmentId')

  const { data: assignmentData, loading: assignmentLoading, mutate } = useAssignment({ id: assignmentId })

  const { userDetail, clinicId } = useAuth()

  const fetchLatestFormVersions = async () => {
    const response = await fetch(`/latest-form-versions.json`)

    if (!response.ok) {
      throw new Error(`Failed to fetch latest form versions: ${response.statusText}`)
    }

    const data: Record<string, string> = await response.json()

    return data
  }

  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {} as FormResult,
  })

  const data = watch()

  // useEffect(() => {
  //   console.log(data)
  // }, [data])

  useEffect(() => {
    const version = searchParams.get('version')
    const assignmentId = searchParams.get('assignmentId')

    if (!version || !assignmentId) {
      setError(true)
      return
    }
  }, [searchParams])

  useEffect(() => {
    if (!userDetail) {
      return
    }

    setUserType(userDetail.role === USER_ROLE.PATIENT ? FormUserType.PATIENT : FormUserType.PHYSICIAN)
  }, [userDetail])

  useEffect(() => {
    if (!assignmentData) return

    if (assignmentData.answers) {
      setSectionAnswers(assignmentData.answers)

      const answers = getAnswersFromSections(assignmentData.answers)

      Object.entries(answers).forEach(([key, value]) => setValue(key, value))

      if (calculateScore) {
        const scores = calculateScore(sections, answers)?.filter(
          ({ userType: ut }) => userType === FormUserType.PHYSICIAN || userType === ut,
        )
        setScores(scores || [])
      }
    }

    if (assignmentData.status === QUESTIONNAIRE_ASSIGNMENT_STATUS.COMPLETED) {
      setState((x) => ({ ...x, readonly: true }))
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [assignmentData, calculateScore, sections, setValue, userType])

  const onSubmit = async (data: FormResult) => {
    if (state.readonly || state.submitted) {
      return
    }

    if (!sections.length) {
      setError(true)
      return
    }

    if (!state.started) {
      await AssignmentActions.updateStatus({ id: assignmentId, status: QUESTIONNAIRE_ASSIGNMENT_STATUS.STARTED })
      setState((x) => ({ ...x, started: true }))
      return
    }

    try {
      setLoading(true)

      if (calculateScore) {
        const scores = calculateScore(sections, data)?.filter(
          ({ userType: ut }) => userType === FormUserType.PHYSICIAN || userType === ut,
        )
        setScores(scores || [])
      }

      const sectionAnswers = getSectionAnswers(sections, data)

      console.log(sectionAnswers)
      console.log(data)

      await AssignmentActions.updateAnswers({ id: assignmentId, answers: sectionAnswers })

      if (sectionAnswers.every((x) => !!x)) {
        await AssignmentActions.updateStatus({ id: assignmentId, status: QUESTIONNAIRE_ASSIGNMENT_STATUS.COMPLETED })
      }

      console.log('SUBMITTED!!!')

      setState((x) => ({ ...x, submitted: true }))
    } catch (err: any) {
      Toast.error(err?.message ?? 'Form submission Failed!')
    } finally {
      setLoading(false)
    }
  }

  const initFormComponent = async (isMounted: boolean) => {
    try {
      const latestVersions = await fetchLatestFormVersions()
      const version = searchParams.get('version') || latestVersions?.[formName || ''] || 'v1'

      console.log(version)

      const LoadedComponent = await loadFormComponent(formName || '', version, setError, setLoading)

      if (isMounted) {
        setFormComponent(() => LoadedComponent)
      }
    } catch (err: any) {
      setError(err)
    } finally {
      if (isMounted) {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    let isMounted = true

    initFormComponent(isMounted)

    return () => {
      isMounted = false
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  if (loading || assignmentLoading) {
    return (
      <div className='flex items-center justify-center'>
        <div className='loading loading-spinner loading-sm' />
      </div>
    )
  }

  if (error) {
    return (
      <div className='flex items-center justify-center'>
        There was a problem loading this form. Please try again later.
      </div>
    )
  }

  const Results = () => (
    <div className='mb-12'>
      <div className='font-semibold text-2xl mb-6'>Results</div>
      <div>
        {scores.map(({ title, value, maxValue }, i) => (
          <div className='flex justify-between text-lg mb-4' key={`score_${i}`}>
            <span
              style={{
                maxWidth: 800,
              }}
            >
              {title}
            </span>
            <span className='text-primary font-semibold'>
              {value.toFixed(1)} / {maxValue}
            </span>
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <form
      className='w-full space-y-4 overflow-hidden relative px-28'
      onSubmit={handleSubmit(onSubmit, Toast.formErrors)}
    >
      {!state.submitted && !showSubmitScreen && (
        <Button
          onClick={(e) => {
            e?.preventDefault()
            const canGoBack = window.history.length > 1

            if (canGoBack) {
              navigate(-1)
            } else {
              navigate(userType === FormUserType.PATIENT ? '/questionnaires' :  `/clinics/${clinicId}/patients/${assignmentData?.patientId}`)
            }
          }}
        >
          {'< Back'}
        </Button>
      )}
      {state.submitted ? (
        <div>
          <div className='text-center font-semibold text-xl mb-12'>Thank you! Your answers was submitted</div>
          <div className='flex items-center justify-center'>
            <Button
              onClick={async (e) => {
                e?.preventDefault()

                navigate(userType === FormUserType.PATIENT ? '/questionnaires' :  `/clinics/${clinicId}/patients/${assignmentData?.patientId}`)

                await mutate()
              }}
            >
              Return to {userType === FormUserType.PATIENT ? 'portal' : 'patient'}
            </Button>
          </div>
          <div>
            {!!scores.length && <Results />}
            <div className='font-semibold text-2xl'>Your answers</div>
            {sections
              .filter(({ userType: ut }) => userType === FormUserType.PHYSICIAN || userType === ut)
              .map((section, i) => (
                <div key={`answers_${i}`}>
                  <div>
                    <div className='text-xl font-semibold my-8'>
                      {sections.length > 1 && `${i + 1}. `}
                      {section.title}
                    </div>
                    {section.questions.map((_question, i) => (
                      <FormComponentRenderer
                        key={`form_component_answer_${i}`}
                        currentQuestion={i}
                        questions={section.questions}
                        value={data}
                        type={'answer'}
                        setValue={setValue}
                      />
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      ) : state.started || state.readonly ? (
        <div className='space-y-4 pb-36'>
          {showSubmitScreen ? (
            <div className='mb-12'>
              <div className='font-semibold text-2xl'>Please check your answers before finishing the form</div>
              {sections
                .filter(({ userType: ut }) => userType === FormUserType.PHYSICIAN || userType === ut)
                .map((section, i) => (
                  <div key={`answers_${i}`}>
                    <div>
                      <div className='text-2xl font-semibold my-8'>
                        {sections.length > 1 && `${i + 1}. `}
                        {section.title}{' '}
                        {!!sectionAnswers?.[i] && <span className='text-green-700 ml-2'>COMPLETED ✓</span>}
                      </div>
                      {section.questions.map((_question, i) => (
                        <FormComponentRenderer
                          key={`form_component_answer_${i}`}
                          currentQuestion={i}
                          questions={section.questions}
                          value={data}
                          type={'answer'}
                          setValue={setValue}
                        />
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <Suspense
              fallback={
                <div className='flex items-center justify-center'>
                  <div className='loading loading-spinner loading-sm' />
                </div>
              }
            >
              {FormComponent ? (
                <FormComponent
                  userType={userType}
                  control={control}
                  data={data}
                  setValue={setValue}
                  setSections={setSections}
                  setDisplayFormName={setDisplayFormName}
                  sectionAnswers={sectionAnswers}
                />
              ) : null}
              {!!scores.length && state.readonly && <Results />}
            </Suspense>
          )}
          {!state.readonly && (
            <FormFooter
              questions={getQuestions(sections, userType || FormUserType.PATIENT)}
              data={data}
              showSubmitScreen={showSubmitScreen}
              setShowSubmitScreen={setShowSubmitScreen}
            />
          )}
        </div>
      ) : (
        <div className='space-y-10'>
          <div className='text-center'>
            <div className='font-bold text-3xl mb-8'>{displayFormName}</div>
            <div className='pt-2 text-xl'>When you are ready to start the test, click the button below.</div>
          </div>
          <div className='flex justify-center'>
            <Button className='w-72'>Start Questionnaire</Button>
          </div>
        </div>
      )}
      <Suspense
        fallback={
          <div className='flex items-center justify-center'>
            <div className='loading loading-spinner loading-sm' />
          </div>
        }
      >
        {FormComponent ? (
          <FormComponent
            userType={userType}
            control={control}
            data={data}
            setValue={setValue}
            setSections={setSections}
            setDisplayFormName={setDisplayFormName}
            setCalculateScore={setCalculateScore}
            loader={true}
          />
        ) : null}
      </Suspense>
    </form>
  )
}
