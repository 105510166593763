import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from 'components/Button'
import { Dropdown } from 'components/Dropdown'
import { Input } from 'components/Input'
import { useAuth, useTitle } from 'hooks'
import { IColumn, IOrderBy, TableSettings } from 'components/Table/types'
import { Table } from 'components/Table'
import Pagination from 'components/Pagination'
import { resetShortEndDate, resetShortStartDate } from 'lib/day'
import { QUESTIONNAIRE_ASSIGNMENT_STATUS } from 'types/dist'
import { useQuestionnairesForms, useQuestionnairesList } from 'hooks/useQuestionnaires'
import { useUserSettings } from 'hooks/useUserSettings'
import { AssignmentDueDate } from './AssignmentDueDate'
import { AssignmentStatus } from './AssignmentStatus'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { DashboardDateFilter } from './DashboardDateFilter'

dayjs.extend(utc)

interface QuestionnaireTable {
  id: string
  firstName: string
  lastName: string
  phone: string
  dob: string
  dueDate: {
    value: string
    render: JSX.Element
  }
  completedDate: string
  type: string
  status: {
    value: string
    render: JSX.Element
  }
  action: {
    value: string
    render: JSX.Element
  }
}

const defaultColumns: IColumn<QuestionnaireTable>[] = [
  { label: 'Last Name', key: 'lastName' },
  { label: 'First Name', key: 'firstName' },
  { label: 'Phone', key: 'phone' },
  { label: 'DOB', key: 'dob' },
  { label: 'Type', key: 'type' },
  { label: 'Due Date', key: 'dueDate' },
  { label: 'Complete Date', key: 'completedDate' },
  { label: 'Status', key: 'status' },
  { label: 'Action', key: 'action' },
]

const statusFilters = [
  { label: 'Queued', value: QUESTIONNAIRE_ASSIGNMENT_STATUS.QUEUED },
  { label: 'Completed', value: QUESTIONNAIRE_ASSIGNMENT_STATUS.COMPLETED },
  { label: 'Overdue', value: QUESTIONNAIRE_ASSIGNMENT_STATUS.OVERDUE },
  { label: 'Dormant', value: QUESTIONNAIRE_ASSIGNMENT_STATUS.DORMANT },
  { label: 'Started', value: QUESTIONNAIRE_ASSIGNMENT_STATUS.STARTED },
]

interface QuestionnaireSearch {
  dueFrom?: string
  dueTo?: string
  completedFrom?: string
  completedTo?: string
  type?: string
  firstName?: string
  lastName?: string
  status?: string
}

const PAGE_SIZE = 10

export const QuestionnairesAdminDashboard = () => {
  useTitle('Questionnaires')

  const { getSortListingByScreen, getColumns, saveSortListing, saveColumns } = useUserSettings()

  const [columns, setColumns] = useState(getColumns<IColumn<QuestionnaireTable>[]>('questionnaires') || defaultColumns)
  const sortListingDefaults = getSortListingByScreen('questionnaires')
  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate()

  const { clinicId } = useAuth()

  const defaultSearch: QuestionnaireSearch = {
    firstName: searchParams.get('firstName') || '',
    lastName: searchParams.get('lastName') || '',
    dueFrom: searchParams.get('dueFrom') || '',
    dueTo: searchParams.get('dueTo') || '',
    completedFrom: searchParams.get('completedFrom') || '',
    completedTo: searchParams.get('completedTo') || '',
    type: searchParams.get('type') || '',
    status: searchParams.get('status') || '',
  }

  const orderBy = {
    fieldName:
      (searchParams.get('orderby_field') as IOrderBy<QuestionnaireTable>['fieldName']) ||
      sortListingDefaults?.fieldName ||
      'firstName',
    order:
      (searchParams.get('orderby_order') as IOrderBy<QuestionnaireTable>['order']) ||
      sortListingDefaults?.order ||
      'DESC',
  }

  const { register, handleSubmit, control, setValue, watch } = useForm({
    defaultValues: defaultSearch,
  })

  const status = watch('status')

  const showCompletedDateFilter = status === QUESTIONNAIRE_ASSIGNMENT_STATUS.COMPLETED
  const showDueDateFilter = !status || status !== QUESTIONNAIRE_ASSIGNMENT_STATUS.COMPLETED

  const conditionalParam = (condition: boolean, key: string, value: string | undefined | null) =>
    condition ? { [key]: value } : {}

  const query = {
    page: +(searchParams.get('page') || '1') - 1,
    id: clinicId,
    size: PAGE_SIZE,
    ...conditionalParam(
      !!searchParams.get('dueFrom') && showDueDateFilter,
      'dueFrom',
      resetShortStartDate(searchParams.get('dueFrom') || ''),
    ),
    ...conditionalParam(
      !!searchParams.get('dueTo') && showDueDateFilter,
      'dueTo',
      resetShortEndDate(searchParams.get('dueTo') || ''),
    ),
    ...conditionalParam(
      !!searchParams.get('completedFrom') && showCompletedDateFilter,
      'completedFrom',
      resetShortStartDate(searchParams.get('completedFrom') || ''),
    ),
    ...conditionalParam(
      !!searchParams.get('completedTo') && showCompletedDateFilter,
      'completedTo',
      resetShortEndDate(searchParams.get('completedTo') || ''),
    ),
    ...conditionalParam(!!searchParams.get('type'), 'type', searchParams.get('type')),
    ...conditionalParam(!!searchParams.get('firstName'), 'firstName', searchParams.get('firstName')),
    ...conditionalParam(!!searchParams.get('lastName'), 'lastName', searchParams.get('lastName')),
    ...conditionalParam(!!searchParams.get('status'), 'status', searchParams.get('status')),
    orderby_field: orderBy.fieldName,
    orderby_order: orderBy.order,
  }
  const { data: paginatedData, mutate } = useQuestionnairesList(query)
  const { data: questionnaireForms } = useQuestionnairesForms({ id: clinicId })
  const data = useMemo(() => paginatedData?.data || [], [paginatedData?.data])

  const questionnaireTypes = (questionnaireForms || []).map((x: { name: string; type: string }) => ({
    label: x.name,
    value: x.type,
  }))

  const questionnaireRows = useMemo(() => {
    return data.map((questionnaire: any) => {
      const row: QuestionnaireTable = {
        id: questionnaire.id,
        firstName: questionnaire.patient.firstName,
        lastName: questionnaire.patient.lastName,
        phone: questionnaire.patient.phone,
        dob: new Date(questionnaire.patient.dob).toLocaleDateString(),
        dueDate: {
          value: questionnaire.dueDate,
          render: (
            <div onMouseDown={(e) => e.stopPropagation()}>
              <AssignmentDueDate
                assignmentId={questionnaire.id}
                patientId={questionnaire.patientId}
                currentDueDate={dayjs(questionnaire.dueDate).utc().format('MMM DD, YYYY')}
                mutate={mutate}
                setLoading={() => {}}
              />
            </div>
          ),
        },
        completedDate: questionnaire.completeDate ? new Date(questionnaire.completeDate).toLocaleDateString() : '-',
        type: questionnaire.form.name,
        status: {
          value: questionnaire.status,
          render: <AssignmentStatus status={questionnaire.status} answers={questionnaire.answers} />,
        },
        action: {
          value: questionnaire.form.type,
          render: (
            <Button
              onClick={() =>
                navigate(
                  `/form/${questionnaire.form.type}?version=v${questionnaire.form.version}&assignmentId=${questionnaire.id}`,
                )
              }
            >
              {questionnaire.status === 'completed' ? 'View' : 'Start'}
            </Button>
          ),
        },
      }

      return row
    })
  }, [data, mutate, navigate])

  const onSubmit = (data: QuestionnaireSearch) => {
    setSearchParams(
      {
        ...conditionalParam(!!data.dueFrom && showDueDateFilter, 'dueFrom', data.dueFrom),
        ...conditionalParam(!!data.dueTo && showDueDateFilter, 'dueTo', data.dueTo),
        ...conditionalParam(!!data.completedFrom && showCompletedDateFilter, 'completedFrom', data.completedFrom),
        ...conditionalParam(!!data.completedTo && showCompletedDateFilter, 'completedTo', data.completedTo),
        ...conditionalParam(!!data.type, 'type', data.type),
        ...conditionalParam(!!data.firstName, 'firstName', data.firstName),
        ...conditionalParam(!!data.lastName, 'lastName', data.lastName),
        ...conditionalParam(!!data.status, 'status', data.status),
        orderby_field: orderBy.fieldName,
        orderby_order: orderBy.order,
      },
      { replace: true },
    )
  }

  useEffect(() => {
    if (!showDueDateFilter) {
      setValue('dueTo', '')
      setValue('dueFrom', '')
    }

    if (!showCompletedDateFilter) {
      setValue('completedTo', '')
      setValue('completedFrom', '')
    }
  }, [setValue, showDueDateFilter, showCompletedDateFilter])

  const onOrderBy = (options: IOrderBy<QuestionnaireTable>) => {
    setSearchParams(
      (params) => {
        params.set('orderby_field', options.fieldName)
        params.set('orderby_order', options.order)
        return params
      },
      { replace: true },
    )

    saveSortListing({
      screenName: 'questionnaires',
      fieldName: options.fieldName,
      order: options.order,
    })
  }

  const onPageChange = (nextPage: number) => {
    setSearchParams(
      (params) => {
        params.set('page', nextPage.toString())
        return params
      },
      { replace: true },
    )
  }

  const onSaveTableSettings = (tableSettings: TableSettings) => {
    const columns = tableSettings.columns as unknown as IColumn<QuestionnaireTable>[]
    const columnsToSave = columns.length === 0 ? defaultColumns : columns
    setColumns(columnsToSave)
    saveColumns({
      screenName: 'questionnaires',
      columns: tableSettings.columns,
    })
  }

  return (
    <div className='w-full space-y-4 pb-4'>
      {/* Top Bar */}
      <div className='flex px-6 py-4 justify-between align-middle self-stretch items-center shadow-md border-2 border-gray-50 my-6 rounded-lg'>
        <span className='text-2xl font-medium mr-8'>Questionnaires</span>
      </div>

      {/* Search */}
      <form className='px-6 py-4 bg-light-blue rounded-lg space-y-4' onSubmit={handleSubmit(onSubmit)}>
        <div className='flex justify-between space-y-2'>
          <h4 className='font-medium text-base'>Search for Questionnaires</h4>

          <div>
            <Button className='min-h-8 h-8 bg-secondary font-light lg:px-6' type='submit'>
              Search
            </Button>
          </div>
        </div>

        <div className='flex gap-4 flex-wrap'>
          <DashboardDateFilter
            showFilter={showDueDateFilter}
            label={'Due Date'}
            setValue={setValue}
            defaultSearch={defaultSearch}
            keyFrom={'dueFrom'}
            keyTo={'dueTo'}
          />
          <DashboardDateFilter
            showFilter={showCompletedDateFilter}
            label={'Complete Date'}
            setValue={setValue}
            defaultSearch={defaultSearch}
            keyFrom={'completedFrom'}
            keyTo={'completedTo'}
          />

          <div>
            <Controller
              name='type'
              control={control}
              render={({ field }) => (
                <Dropdown
                  value={field.value}
                  label='Type'
                  className='h-12'
                  background='white'
                  placeholder='Choose Type'
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                  options={questionnaireTypes}
                />
              )}
            />
          </div>

          <div className='flex-1'>
            <Controller
              name='status'
              control={control}
              render={({ field }) => {
                return (
                  <Dropdown
                    label='Status'
                    placeholder='Filter by status'
                    options={statusFilters}
                    className='h-12 min-w-[215px]'
                    value={field.value}
                    onChange={(event) => field.onChange(event.target.value)}
                  />
                )
              }}
            />
          </div>

          <div className='flex-1'>
            <Input
              type='text'
              label='First Name'
              placeholder='Enter First Name'
              autoComplete='first-name'
              onClear={() => {
                setValue('firstName', '')
              }}
              showClearOnStart={!!defaultSearch.firstName?.length}
              {...register('firstName')}
            />
          </div>

          <div className='flex-1'>
            <Input
              type='text'
              label='Last Name'
              placeholder='Enter Last Name'
              autoComplete='last-name'
              onClear={() => {
                setValue('lastName', '')
              }}
              showClearOnStart={!!defaultSearch.lastName?.length}
              {...register('lastName')}
            />
          </div>
        </div>
      </form>

      {/* List */}
      <Table
        className='w-full'
        showSearch={false}
        title={'Questionnaires'}
        rows={questionnaireRows}
        columns={columns}
        orderBy={orderBy}
        onOrderBy={onOrderBy}
        onSaveSettings={onSaveTableSettings}
      />

      {!!paginatedData && (
        <div className='flex justify-end'>
          <Pagination
            currentPage={paginatedData.page + 1}
            totalPages={Math.ceil(paginatedData.total / paginatedData.size)}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  )
}
