import { PaginatedQuery } from './pagination'

export const QUESTIONNAIRE_ASSIGNMENTS_QUEUE = 'QUESTIONNAIRE_ASSIGNMENTS_QUEUE'
export const QUESTIONNAIRE_EXPORT_QUEUE = 'QUESTIONNAIRE_EXPORT_QUEUE'

export enum QUESTIONNAIRE_ASSIGNMENT_STATUS {
  QUEUED = 'queued',
  OVERDUE = 'overdue',
  DORMANT = 'dormant',
  STARTED = 'started',
  COMPLETED = 'completed',
}

export enum QUESTIONNAIRE_ASSIGNMENT_NEXT_BY {
  PROVIDER = 'provider',
  PATIENT = 'patient',
  SYSTEM = 'system',
}

export enum QUESTIONNAIRE_ASSIGNMENT_FREQUENCY {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  SEMI_ANNUALLY = 'semi_annually',
  ANNUALLY = 'annually',
}

export enum QUESTIONNAIRE_ASSIGNMENT_JOB_TYPE {
  ON_START = 'on_start',
  ON_OVERDUE = 'on_overdue',
}

export type FREQUENCY_QUEUED_VALUE = [number, 'day' | 'week' | 'month' | 'year']
export type ASSIGNMENT_START_DATE = Date
export type ASSIGNMENT_DUE_DATE = Date

export type AssignmentRuleQuery = PaginatedQuery<{
  patientId?: string
}>
export type AssignmentRuleOrderByQuery = {
  fieldName: 'name' | 'dueDate' | 'frequency' | 'active' | 'status'
  order: 'ASC' | 'DESC'
}

export type AssignmentJob = {
  checkedAt: string
  id: string
  jobId: string
  type: QUESTIONNAIRE_ASSIGNMENT_JOB_TYPE
  created: string
  updated: string
}

export type AssignmentForm = {
  type: string
  questions: Question[]
  active: boolean
  id: string
  name: string
  version: string
  created: string
  updated: string
}

export type Assignment = {
  completeDate: string | null
  answers: SectionAnswers[] | null
  status: QUESTIONNAIRE_ASSIGNMENT_STATUS
  nextBy: QUESTIONNAIRE_ASSIGNMENT_NEXT_BY | null
  id: string
  questionnaireFormId: string
  patientId: string
  startDate: string
  dueDate: string
  created: string
  updated: string
  jobs: AssignmentJob[]
  form: AssignmentForm
  active: boolean
}

export type AssignmentRule = {
  nextAssignmentId: string
  active: boolean
  frequency: QUESTIONNAIRE_ASSIGNMENT_FREQUENCY
  patient: any | null
  id: string
  questionnaireFormId: string
  patientId: string
  created: string
  updated: string
  nextAssignment: Assignment
  form: AssignmentForm
}

export type AssignmentQuery = PaginatedQuery<{
  status?: QUESTIONNAIRE_ASSIGNMENT_STATUS | QUESTIONNAIRE_ASSIGNMENT_STATUS[]
  nextBy?: QUESTIONNAIRE_ASSIGNMENT_NEXT_BY
  participants?: FormUserType[]
  patientId?: string
}>
export type AssignmentOrderByQuery = {
  fieldName: 'name' | 'dueDate' | 'status' | 'completeDate'
  order: 'ASC' | 'DESC'
}

export type ClinicQuestionnairesQuery = PaginatedQuery<{
  status?: QUESTIONNAIRE_ASSIGNMENT_STATUS | QUESTIONNAIRE_ASSIGNMENT_STATUS[]
  firstName?: string
  lastName?: string
  completedFrom?: string
  completedTo?: string
  dueFrom?: string
  dueTo?: string
  type?: string
  clinicId: string
}>
export type ClinicQuestionnairesOrderByQuery = {
  fieldName: 'firstName' | 'lastName' | 'type' | 'dueDate' | 'status' | 'phone' | 'dob' | 'completedDate'
  order: 'ASC' | 'DESC'
}

export type AssignmentJobData = {
  assignmentId: string
  assignmentJobId: string
  startDate: string
  dueDate: string
}

export type QuestionnaireExportJobData = {
  assignmentId: string;
}

export type AssignmentBullJob = {
  data: AssignmentJobData
  options: {
    jobId: string
    delay: number
    attempts: number
  }
}

export enum QuestionType {
  SELECT = 'SELECT',
  SCORE = 'SCORE',
  TEXT = 'TEXT',
  MULTIPLE = 'MULTIPLE',
  CONDITIONAL_SELECT = 'CONDITIONAL_SELECT',
}

export enum FormUserType {
  PATIENT = 'PATIENT',
  PHYSICIAN = 'PHYSICIAN',
}

export type Section = {
  title: string
  userType: FormUserType
  questions: Question[]
}

type BaseQuestion<T extends QuestionType> = {
  type: T
  title: string
  key: string
  description?: string
}

type Variant = {
  label: string
  value: string
  scoreWeight?: number
}

export type SelectQuestion = BaseQuestion<QuestionType.SELECT> & {
  multipleAnswers?: boolean
  provideOther?: boolean
  variants: Variant[]
}

export type MultipleQuestion = BaseQuestion<QuestionType.MULTIPLE> & {
  questions: Question[]
}

export type ConditionalSelectQuestion = BaseQuestion<QuestionType.CONDITIONAL_SELECT> & {
  variants: Variant[]
  showIf: string
  question: Question
}

export type ScoreQuestion = BaseQuestion<QuestionType.SCORE> & {
  maxValue: number
}

export type TextQuestion = BaseQuestion<QuestionType.TEXT>

export type FormResult = Record<string, any>

export type Score = {
  title: string
  userType: FormUserType
  value: number
  maxValue: number
}

export type CalculateScoreFunction = (sections: Section[], data: FormResult) => Score[]

export type BaseForm = {
  formName: string
  sections: Section[]
  calculateScore?: CalculateScoreFunction
}
export type SectionAnswers = FormResult | null

export type Question = SelectQuestion | ScoreQuestion | TextQuestion | ConditionalSelectQuestion | MultipleQuestion

type AnyObject = Record<string, unknown>

export type QuestionnaireExportData = {
  id: string
  answers: AnyObject[] | null,
  formName: string,
  clinicName: string,
  clinicId: string,
  patient: {
    id: string;
    firstName: string;
    lastName: string;
    createdAt: string;
  }
  date: string
}
