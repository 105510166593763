import { WebAuth, ChangePasswordOptions } from 'auth0-js'
import { config } from 'config'
import * as storage from 'lib/storage'

export const auth = new WebAuth({
  domain: config.auth0.domain,
  clientID: config.auth0.clientId,
  redirectUri: config.auth0.redirect,
  responseType: 'token id_token',
  audience: config.auth0.audience,
  scope: config.auth0.scope,
})

export const signin = ({ email, password }: { email: string; password: string }) => {
  return new Promise((resolve, reject) => {
    auth.login({ email, password, realm: config.auth0.realm, state: config.auth0.state }, (error, result) => {
      if (error) return reject(error)
      resolve(result)
    })
  })
}

export const signout = () => {
  auth.logout({
    returnTo: window.location.origin,
  })

  storage.del('user')
  storage.del('token')
}

export const refreshSession = async (): Promise<{ accessToken: string }> => {
  return await new Promise((resolve, reject) => {
    auth.checkSession({}, (error, result) => {
      if (error) return reject(error)
      resolve(result)
    })
  })
}

export const parseHash = () => {
  return new Promise((resolve, reject) => {
    auth.parseHash({ state: config.auth0.state }, (error, result) => {
      if (error) return reject(error)
      if (!result?.accessToken || !result?.idToken) return reject(new Error('Invalid credentials'))
      resolve(result?.accessToken)
    })
  })
}

export const getUserInfo = (token: string) => {
  return new Promise((resolve, reject) => {
    auth.client.userInfo(token, (error, user) => {
      if (error) return reject(error)
      resolve({ user, token })
    })
  })
}

export const resetPassword = (email: string): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const options: ChangePasswordOptions = {
      connection: config.auth0.realm,
      email,
    }
    auth.changePassword(options, (error) => {
      if (error) return reject(error)
      resolve()
    })
  })
}
