import { useEffect, useRef } from 'react'
import * as storage from 'lib/storage'
import { useAuth } from './useAuth'

export const useLogout = (inactivityTime: number, unloadTime: number) => {
  const auth = useAuth()

  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  const lastActivity = useRef<number>(Date.now())

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'mousedown', 'touchstart', 'scroll', 'focus']

    const handleActivity = () => {
      lastActivity.current = Date.now()
    }

    const checkActivity = () => {
      const currentTime = Date.now()
      const timeSinceLastActivity = currentTime - lastActivity.current

      if (timeSinceLastActivity >= inactivityTime) {
        auth.signout()
      }
    }

    intervalRef.current = setInterval(checkActivity, 1000)

    // user closed the page - logging out
    const handleBeforeUnload = () => {
      storage.set('unloadTimestamp', Date.now())
    }

    const handleOnLoad = () => {
      const unloadTimestamp = storage.get('unloadTimestamp')
      if (!unloadTimestamp) return

      storage.del('unloadTimestamp')

      const currentTime = Date.now()
      const timeSinceLastActivity = currentTime - unloadTimestamp

      if (timeSinceLastActivity >= unloadTime) {
        auth.signout()
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('load', handleOnLoad)
    events.forEach((event) => window.addEventListener(event, handleActivity))

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      window.removeEventListener('load', handleOnLoad)
      events.forEach((event) => window.removeEventListener(event, handleActivity))

      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])
}
